.tableauBordContainer {
	height: 100%;
	font-family: 'Century Gothic';
	padding-bottom: 50px;

	h1 {
		text-align: center;
		transform: translateY(-45%);
		margin-top: 45vh;
	}

	h4 {
		margin: 3%;
	}

	.listeconteneur {
		margin-top: 10%;
		width: 100%;
		margin: auto;
	}

	.lastUpdatedSurvey-wrapper{
		width: 100%;
		height: 100%;
		display:flex;
		flex-direction: column;
		justify-content: flex-start;
		align-items: center;

		h2{
			width: 100%;
			font-family: 'Century Gothic';
			color: #26305E;
			text-align: center;
			margin-top: 10%;
		}
	}

}




@media only screen and (min-width: 480px) {
	.fondGaucheTDB {
		background-image: url(/images/fondTDB1.png);
		background-repeat: no-repeat;
		background-size: cover;
		width: 100%;
		min-height: 70vh;
		display: flex;
		justify-content: center;
		align-items: center;
	}
	.fondGaucheTDB h1{
		margin:0px 50px 0px 50px;
		transform: translateY(0)
	}

	.tableauBordContainer {
		min-height: 70vh;
	}
}
