.clientContainer {
	min-height: 100vh;

	.listeconteneur {
		margin-top: 10%;
	}
}
@media only screen and (min-width: 480px) {
	.clientContainer {
		min-height: 70vh;

		.listeconteneur {
			margin: auto;
			width: 70%;
		}
	}
}
