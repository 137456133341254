.creaXlsContainer {
	min-height: 100vh;

	h3 {
		padding-left: 3%;
	}
	.listeconteneur {
		margin-top: 10%;
	}
	.importFocusTxt {
		font: normal normal normal 16px/21px Century Gothic;
		letter-spacing: 0px;
		color: #707070;
		opacity: 1;
	}
	.MuiSvgIcon-fontSizeLarge {
		font-size: 4rem;
	}
}
@media only screen and (min-width: 480px) {
	.creaXlsContainer {
		min-height: 70vh;

		.listeconteneur {
			margin: auto;
			width: 70%;
		}
	}
}
