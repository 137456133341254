.creaQuestionContainer {
	min-height: 100vh;

	h3 {
		padding-left: 3%;
	}
	.listeconteneur {
		margin-top: 10%;
	}
	.inputRounded {
		border: 1px solid #cad5e7;
		border-radius: 20px;

		color: #000000;
		cursor: text;
		display: inline-flex;
		position: relative;
		font-size: 1rem;
		box-sizing: border-box;
		align-items: center;
		font-family: "Century Gothic";
		font-weight: 400;
		line-height: 2rem;
		text-align: center;
	}
	.inputRounded:focus {
		outline: 0;
	}

	.MuiTypography-root{
		width: 100%;
	}
	.boutonCentrer{
		margin-top: 50px;
		margin-bottom: 50px;
	}

	.panelList-wrapper{
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		margin-top: 20px;
	}

	.panel-wrapper{
		width: 90%;
	}

	.panel-wrapper-expanded {
		width: 90%;
		transition: 0.5s;
		background-color: white;

	}

	.panel-wrapper-expanded .MuiAccordionSummary-root{
		background-color: #c77e00;
		color:white;
		font-weight: bold;
		transition: 0.5s;	
		position: -webkit-sticky;
		position: sticky;
		top: 65px;
		z-index: 100;
	}

	.MuiList-root li div:hover{
		color:white;
		transition: 0.1s;
	}

}
@media only screen and (min-width: 480px) {
	.creaQuestionContainer {
		min-height: 70vh;

		.listeconteneur {
			margin: auto;
			width: 70%;
		}
	}
}
@media only screen and (max-width: 480px) {
	.creaQuestionContainer {
		.themeTitle {
			text-align: center;
			padding: 0;
		}
	}
}
