.listeProject {
	font-family: 'Century Gothic';
	font-size: 14px;
	color:#707070;

	.separator {
		width: 100%;
	}

	.listeProject-lign {
		display: flex;
		justify-content: space-between;
		align-items: center;
		width: 100%;
		padding: 15px 0px 15px 2px;
		border-bottom: 1px solid rgb(209, 208, 208);
	}

	.listeProject-name{
		cursor: pointer;
	}
	

	.focusCount {
		display: flex;
		justify-content: center;
		align-items: center;
		margin-right:30px;
		font-size: 14px !important;

	}

	.listeProject-arrow{
		font-weight: bold;
		color: #C77D00;
		font-size: 14px;
		vertical-align: baseline;
		padding-left:5px;
	
	}

	.listeProject-deleteIcon{
		// display: flex;
		// justify-content: center;
		// align-items: center;
		width: 14px !important;

		img{
			width: 100%;
			height: 100%;
			margin-left: 30px !important;
			cursor: pointer;
		}
		
	}

	.listeProject-lign-popup{
		position: fixed;
		top:0;
		bottom:0;
		left:0;
		right:0;
		height: 100%;
		width: 100%;
		z-index: 10;
		background-color: rgba(0, 0, 0, 0.027);
		display: flex;
		justify-content: center;
		align-items: center;
		overflow: hidden;

		.lign-popup-content{
			display: flex;
			justify-content: space-around;
			align-items: center;
			flex-direction: column;
			background-color: white;
			width: 500px;
			height: 300px;
			border-radius: 10px;
			p{
				font-size: 26px;
				margin: 0px;
			}

			.lign-popup-label{
				font-size: 22px;
			}
			
		}

		.lign-popup-button{
			display: flex;
			justify-content: center;
			align-items: center;
				

			p{
				padding:10px 15px 10px 15px;
				background-color: grey;
				margin:0px 10px 0px 10px;
				width: 100px;
				display: flex;
				justify-content: center;
				align-items: center;
				color:white;
				font-size: 16px;
				cursor: pointer;

			}
			p:hover{
				background-color: rgb(161, 161, 161);

			}
			p:last-child{
				background-color: #C77D00;

			}
			p:last-child:hover{
				background-color: #d68f13;

			}
		}
	}

}

.listFocus {
	padding-top: '30px';
	li {
		display: flex;
		justify-content: space-between;

	}
	span {
		height: 100%;
	}

	.listItem {
		font-size: '14px';

	}
}


.textRight {
	text-align: right;
}
