.rootSearch {
	padding: 2px 4px;
	display: flex;
	align-items: center;
	width: 30%;
	border: 1px #cad5e7 solid;
	border-radius: 20px;
	margin: auto;
	margin-bottom: 5%;
}

.rootSearch-glass{
	display: flex;
	justify-content: center;
	align-items: center;
}

.iconButton {
	padding: 10;
}

@media only screen and (max-width: 480px) {
	.rootSearch {
		width: 90%;
	}
}
