.EnteteLettre {
	background-color: #cad5e7;
	color: #ffffff;
	font-size: 14px;
	font-weight: normal;
	padding-left: 27px;
	padding-top: 11px;
	padding-bottom: 11px;
}

.ListeElement {
	font-size: 14px;
	color: #707070;
}

.trait {
	margin-bottom: 1%;
	margin-top: 0.5%;
	color: #cad5e7;
	margin-right: 10%;
}

.label {
	color: #707070;
	opacity: 1;
}
