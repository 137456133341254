.createPopUp {
	text-align: center;
	.inputCreate {
		padding: 2px 4px 2px 1rem;
		display: flex;
		width: 70%;
		border: 1px #cad5e7 solid;
		border-radius: 20px;
		margin: auto;
		height: 2.5rem;
		outline: none;
	}
	.cancelButton {
		background: #374785 0% 0% no-repeat padding-box;
		box-shadow: 0px 3px 6px #00000029;
		border: 1px solid #cad5e7;
		border-radius: 3px;
		color: white;
		width: 250px;
		margin:20px;
		
	}

	.heading {
		font-family: "Century Gothic";
		text-align: center;
		font-size: 1.5rem;
		letter-spacing: 0px;
		color: #707070;
		font-weight: 100;
		margin-bottom: 30px;
		margin-top:30px;
	}

	.subheading{
		margin-bottom: 40px;
	}

	input{
		margin-bottom: 30px;
		margin-top:50px;
	}

	input::placeholder {
		color: rgba(128, 128, 128, 0.699);
		font-size: 16px;
		font-family: 'Century Gothic';
		text-align: center;
	}

	.buttonWrapper{
		margin-top: 20px;
	}

	.validButton {
		background: var(--unnamed-color-c77d00) 0% 0% no-repeat padding-box;
		border: 1px solid var(--unnamed-color-cad5e7);
		background: #c77d00 0% 0% no-repeat padding-box;
		box-shadow: 0px 3px 6px #00000029;
		border: 1px solid #cad5e7;
		border-radius: 4px;
		color: white;
		width: 250px;
		color:white !important;
		margin:20px;
	}
	.validButton.MuiButton-root.Mui-disabled {
		color: #bdbdbd;
		background: #9c9c9c47 0% 0% no-repeat padding-box;
	}
	.cancelButton:hover {
		background: #7f7f7f 0% 0% no-repeat padding-box;
	}
	.validButton:hover {
		background: #7f7f7f 0% 0% no-repeat padding-box;
	}

	.validButton-disabled{
		background: var(--unnamed-color-c77d00) 0% 0% no-repeat padding-box;
		border: 1px solid var(--unnamed-color-cad5e7);
		background: #c77d00 0% 0% no-repeat padding-box;
		box-shadow: 0px 3px 6px #00000029;
		background-color: grey;
		border: 1px solid #cad5e7;
		border-radius: 4px;
		width: 250px;
		color:white !important;
		margin:20px;

	}

}
