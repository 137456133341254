@media only screen and (max-width: 480px) {
	.form-signin {
		padding: 20px;
		margin: 30% auto 0 auto;
		width: 70%;
		height: 65%;
		background: #ffffff;
		border-radius: 6px;
	}

	.fondDroite {
		background-image: url(/images/fondLogin-mobile.jpg);
		height: 100vh;
		background-repeat: no-repeat;
		background-size: cover;
	}
}

@media only screen and (min-height: 700px) {
	.form-signin {
		padding: 20px;
		margin: 30% auto 0 auto;
		width: 70%;
		height: 50%;
		background: #ffffff;
		border-radius: 6px;
	}
}

@media only screen and (min-width: 481px) {
	.form-signin {
		padding: 20px;
		margin: 20% auto;
		width: 20%;
		height: 40%;
		background: #ffffff;
		border-radius: 6px;
		min-width: 300px;
		min-height: 450px;
	}
	.fondDroite {
		background-color: #f7eceb;
	}
}
.versionNumber {
	color: #c7c7c7;
	padding: 5px;
	bottom: 0;
	right: 0;
	position: absolute;
}
.form-signin {
	.form-control {
		padding: 10px;
		font-size: 16px;
		width: fit-content;
		margin: auto;
	}
	h4 {
		text-align: center;
	}
	.logo {
		width: 150px;
		display: block;
		margin: auto;
		padding-top: 20%;
	}
}

.form-signin .libelle {
	font-size: 13px;
	float: left;
	font-weight: bold;
}
.form-signin input {
	border: 1px solid lightgrey;
	border-radius: 3px;
	padding: 5px;
}
.form-signin input:focus {
	border: 1px solid #c77d00;
	outline: #c77d00;
}
.fondGaucheDesktop {
	background-image: url(/images/fondLogin-desktop.jpg);
	height: 100vh;
	background-repeat: no-repeat;
	background-size: cover;
	width: 100%;
}


.boutonCentrer{
	margin-top: 20px;

}

.forgottenPassword{
	width: 100%;
	margin-top: 50px;
	text-align: center;
	font-family: 'Century Gothic';
	text-decoration: underline;
	cursor: pointer;
}