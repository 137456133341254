.forgotPassword-wrapper{
	display: flex;
	justify-content: space-around;
	align-items: center;
	flex-direction: column;
	font-family: 'Century Gothic';

	.logo{
		margin:0px;
		padding:0px
	}

	p{
		font-weight: bold;
		color:grey;
		margin:0px;
		width: 100%;
	}

	.forgotPassword-wrapper-text{
		width: 80%;
		display: flex;
		justify-content: center;
		align-items: center;
		flex-direction: column;
		

		input{
			width: 95%;
		}

		a{
			margin-top:10px;
			height: 16px;
		}
	}
	
	

}
