.progressionContainer {
	min-height: 800px;

	.padding {
		padding-left: 10%;
	}

	.MuiGrid-item.enteteProgression {
		padding: 1rem;
		color: #707070;
		font-size: 1rem;
		text-align: left;
		font-weight: bold;
		margin-bottom: 1rem;
	}
	.groupeRepondant {
		margin-top: 1.5rem;
		margin-bottom: 1.5rem;
		width: 65%;
		font-family: 'Century Gothic';
	}
	.radioButton {
		padding: 0;
	}

	.progressSmall {
		width: 50%;
	}
	.progressBig {
		width: 80%;
	}
	.blueLine {
		background-color: #f6f6f6;
		padding-top: 0;
		margin: 0;
		color: #707070;
		line-height: 2rem;
		font-size: 0.9rem;
	}
	.whiteLine {
		background-color: white;
		padding-top: 0;
		margin: 0;
		color: #707070;
		line-height: 2rem;
		font-size: 0.9rem;
	}
	.statusColumn {
		display: flex;
		justify-content: center;
		align-items: center;
		font-size: 0.9rem;
		height: 100%;
	}
	.success {
		background: #00cc81;
		color: white;
	}

	.error {
		background: #cc3600;
		color: white;
	}
	.pendingBlue {
		background: white;
		color: #707070;
	}
	.pendingWhite {
		background: #f6f6f6;
		color: #707070;
	}
	.line {
		width: 100%;
		margin-bottom: 10px;
	}
	.borderRight {
		border-right: 5px solid white;
	}
	.form-select {
		background: #f6f6f6;
		min-width: 195px;
		font-size: 21px;
		color: #707070;
		font-family: 'Century Gothic';
	}
}

.menu-item-root {
	background: #f6f6f6 !important;
}
.menu-item-selected {
	background: #ececec !important;
}
menu-item-select:hover {
	color: black !important;
}

.mainContent {
	.projectDescription {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		width: 100%;
		font-family: 'Century Gothic';

		.project {
			margin: 0;
			font-size: 36px;
			color: #c77d00;
			font-weight: bold;
			margin-top: 30px;
		}
		.client {
			margin: 0;
			color: #374785;
			font-weight: bold;
			font-size: 20px;
			margin-top: 15px;
		}
		.focus {
			margin: 0;
			color: #707070;
			font-weight: bold;
			font-size: 14px;
			margin-top: 15px;
		}
		.statut {
			margin: 0;
			color: #707070;
			font-weight: bold;
			font-size: 14px;
			margin-top: 15px;
		}
	}

	.actionBlock {
		display: flex;
		justify-content: space-between;
		align-items: center;
		width: 65%;
		background-color: #fcfcfc;
		border-radius: 13px;
		padding: 35px 0px 35px 0px;
		margin-top: 50px;
		margin-bottom: 50px;
		border: 1px solid #f8f8f8;
		flex-wrap: wrap;
		font-family: 'Century Gothic';

		.advancement {
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			color: #c77d00;
			font-size: 40px;
			font-weight: bold;
			margin-left: 70px;

			p {
				font-size: 20px;
				color: #707070;
				font-weight: 300;
				margin-top: 0;
			}
		}

		.iconAction {
			display: flex;
			justify-content: center;
			align-items: center;
			padding-top: 30px;
			min-width: 50%;
		}
	}

	.wrapper-AllListRepondants {
		width: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
	}

	.repondantsTitle {
		width: 100%;
		text-align: center;
		font-size: 21px;
		color: #707070;
		font-family: 'Century Gothic';
		margin: 0;
	}

	.title {
		width: 100%;
		color: #707070;
		font-weight: bold;
		font-size: 20px;
	}
	.subtitle {
		font-size: 12px;
		font-weight: 300;
		text-align: center;
	}

	.listRespondant {
		width: 100%;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		font-family: 'Century Gothic';
	}
}

@media only screen and (max-width: 480px) {
	.progressionContainer {
		width: 90%;
		margin: auto;
		.progressSmall {
			width: 90%;
		}
		.progressBig {
			width: 100%;
		}
		.line {
			width: 100%;
			margin: auto;
		}
	}
}

.editable {
	display: flex;
	align-items: center;
	height: 100%;
	p {
		overflow: hidden;
		text-overflow: ellipsis;
		padding-right: 10px;
		margin: 0;
	}
	input {
        border-radius: 5px;
        padding:5px;
        border: 1px solid rgb(155, 155, 155);
        font-family: 'Century Gothic';
        transition: 1s;
        font-size: 14px;
        margin-right: 2%;
		width: 100%;
	}
    input:focus{
        outline:none;
        border-radius: 5px;
        box-shadow: 0 2px 10px 1px #6161617e;

    }
}
    