.modal {
	background: #0000005e;
	color: white;
	width: 100%;
	height: 100%;
	font-family: "Century Gothic";
	top: 0;
	bottom:0;
	left:0;
	right:0;
	position: absolute;
	overflow: hidden;
	z-index: 100;
}
