.focusedSurveyResume-wrapper{
    width: 93%;
    background-color: #FCFCFC;
    border:1px solid #F8F8F8;
    display:flex;
    justify-content: space-around ;
    align-items: center;
    border-radius: 13px;
    color:#707070;
    margin-bottom: 10px;
    padding:10px;
    cursor: pointer;


    .response-wrapper{
        width: 40%;
        display: flex;
        flex-direction: column;
        align-items: center;

        p{
            margin:0px;
            margin-bottom: 8px;
            font-weight: bold;
        }
        .amount{
            color:#C77D00;
            font-size: 36px;
            font-weight: bold;
        }
    }

    .structure-wrapper{
        width: 50%;

        .client-wrapper{
            font-size: 16px;
            font-weight: bold;

        }

        .projet-wrapper{
            font-size: 13px;
        }

        .focus-wrapper{
            display: flex;
            align-items: center;
            height: 100%;

            img{
                width: 25px;
                margin-right:10px;
            }

            p{
                font-size: 13px;
            }
        }

    }
    .chevron-wrapper{
        width: 10%;
        display: flex;
        justify-content: center;
     cursor: pointer;
        img{
            width: 25px;
        }
    }

}

.focusedSurveyResume-wrapper:hover{
    background-color:  #eeeeee;
}