.administration-wrapper{
    width: 100%;
    height: 100%;
    position: relative;

    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: 'Century Gothic';

    .smallLoader-wrapper{
        position: absolute;
        top:20px;
        right:5px;

        .lds-dual-ring {
            display: inline-block;
            width: 80px;
            height: 80px;
          }
          .lds-dual-ring:after {
            content: " ";
            display: block;
            width: 35px;
            height: 35px;
            margin: 8px;
            border-radius: 50%;
            border: 3px solid grey;
            border-color: grey transparent grey transparent;
            animation: lds-dual-ring 0.4s linear infinite;
          }
          @keyframes lds-dual-ring {
            0% {
              transform: rotate(0deg);
            }
            100% {
              transform: rotate(360deg);
            }
          }
          
          
    }


    .MuiPaper-root {
        border-radius: 0px !important;
    }

 
    .panel-wrapper-expanded{
        transition:1s;
        .MuiAccordionSummary-root{
            background-color: #C77D00;
            color: white;
        }
        .MuiSvgIcon-root{
            color: white;
            fill:white;
            transition:1s;
        }
    }

    h1{
        color:#C77D00;
        font-size: 30px;
        text-align: center;
    }

    .MuiFormControlLabel-root{
        width: 100%;
    }

    .administration-wrapper-content{
        width: 80%;
        h2{

        }
    }

    .administration-wrapper-theme{
        width: 100%;
    h3{
        margin-left:30px;
    }

    }

    .administration-wrapper-skills{
        width: 100%;
        
    }

    .administration-wrapper-separator{
        width: 100%;
        height: 2px;
        background-color: #C77D00;
        margin-top: 10px;
        margin-bottom: 50px;
    }

    .administration-wrapper-add{
        padding-bottom: 30px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;

        p{
            display: flex;
            align-items: center;
            justify-content: center;
            height: 40px;
            width: 90%;
            background-color:#9B9B9B;
            color:white;
            cursor: pointer;
            border: 1px solid #E1E1E1;
            color:white;
            font-size: 16px;
        }
        p:hover{
            background-color:#afafaf;

        }
    }

    .administration-wrapper-add-question{
        padding-bottom: 30px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        p{
            display: flex;
            align-items: center;
            justify-content: center;
            height: 30px;
            width: 90%;
            background-color:#F5F5F5;
            color:white;
            // font-weight: bold;
            cursor: pointer;
            border: 1px solid #E1E1E1;
            color:#8F897A;
            font-size: 14px;

        }

        p:hover{
            background-color:#ebe9e9;

        }
    }

    .administration-wrapper-validateButton{
        padding-bottom: 30px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;

        p{
            display: flex;
            align-items: center;
            justify-content: center;
            height: 50px;
            width: 250px;
            background-color: rgb(42, 104, 42);
            // border-radius: 10px;
            color:white;
            font-weight: bold;
            cursor: pointer;
            padding:5px;

        }

        p:hover{
            background-color:rgb(65, 160, 65);

        }
    }


    .administration-wrapper-add-categorie{
        padding-bottom: 30px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;

        p{
            display: flex;
            align-items: center;
            justify-content: center;
            height: 55px;
            width: 80%;
            background-color:#F5F5F5;
            color:white;
            font-weight: bold;
            cursor: pointer;
            border: 1px solid #E1E1E1;
            color:#8F897A;

        }

        p:hover{
            background-color:#ebe9e9;

        }
    }
}