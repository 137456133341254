.projetContainer {
	min-height: 100vh;

	.listeConteneur {
		padding-top: 5%;
		margin: 5%;
	}
	hr {
		border-top: 1px solid #c7c7c7;
	}
}
@media only screen and (min-width: 480px) {
	.projetContainer {
		min-height: 70vh;
	}
	.listeConteneur {
		margin: auto;
		width: 80%;
	}
}
