/************************************************************************************************/
/***********************************************CSS GLOBAL**************************************/
/*********************************************************************************************/
html {
	background-color: #f8f8f8;
}
@media only screen and (max-width: 480px) {
	html {
		background-color: white;
	}
}

body {
	margin: 0;
}

button.validationButton {
	color: #fff;
	padding: 10px 40px 10px 40px;
	box-shadow: none;
	text-transform: capitalize;
	font-family: 'Century Gothic';
	font-size: 1em;
}
button.validationButtonSmall {
	color: #fff;
	border-radius: 0;
	padding: 5px 15px;
	box-shadow: none;
	text-transform: capitalize;
	font-family: 'Century Gothic';
	font-size: 0.7em;
}
.boutonCentrer {
	text-align: center;
}

.textCenter {
	text-align: center;
}

.mainContent {
	width: 70%;
	margin: auto;
	margin-top: 75px; /*pour etre sure d'avoir le menu et 10px de rab*/
	background-color: white;
}
@media only screen and (max-width: 480px) {
	.mainContent {
		width: 100%;
		margin: inherit;
		margin-top: 10%;
	}
}

.curseur {
	cursor: pointer;
}
.MuiListItem-button:hover {
	text-decoration: none;
	background-color: #c77d00;
	color: white;
}
.MuiListItem-button:first-child:hover {
	color: black;
}

/************************************************************************************************/
/***********************************************CSS ANIMATION***********************************/
/*********************************************************************************************/
@-webkit-keyframes puff-in-center {
	0% {
		-webkit-transform: scale(2);
		transform: scale(2);
		-webkit-filter: blur(4px);
		filter: blur(4px);
		opacity: 0;
	}
	100% {
		-webkit-transform: scale(1);
		transform: scale(1);
		-webkit-filter: blur(0px);
		filter: blur(0px);
		opacity: 1;
	}
}
@keyframes puff-in-center {
	0% {
		-webkit-transform: scale(2);
		transform: scale(2);
		-webkit-filter: blur(4px);
		filter: blur(4px);
		opacity: 0;
	}
	100% {
		-webkit-transform: scale(1);
		transform: scale(1);
		-webkit-filter: blur(0px);
		filter: blur(0px);
		opacity: 1;
	}
}

.puff-in-center {
	-webkit-animation: puff-in-center 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) both;
	animation: puff-in-center 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) both;
}
