@media only screen and (min-width: 480px) {
	.MuiBottomNavigation-root.navigationMenu {
		position: relative;
		top: 0;
		background: #26305e;
		height: 100%;

		.MuiBottomNavigationAction-label {
			color: #ffffff;
			line-height: 20px;
			padding-top: 2%;
			font-size: 14px;
		}
	}

	.menu {
		background: #26305e;
		top: 0;
		position: fixed;
		height: 65px;
	}
	.menu1 {
		top: 0;
		height: 65px;
	}

	.menu2 {
		right: 0;
		height: 65px;
		text-align: right;
	}
	.logoTxt {
		text-align: left;
		font: normal normal normal 1.2rem Century Gothic;
		color: #ffffff;
		padding-left: 2rem;
	}
}
@media only screen and (max-width: 1060px) {
	.logoTxt {
		text-align: left;
		font: normal normal normal 1rem Century Gothic;
		color: #ffffff;
		padding-left: 0.5rem;
	}
}
@media only screen and (max-width: 480px) {
	.MuiBottomNavigation-root.navigationMenu {
		width: 100%;
		position: relative;
		bottom: 0;
		background: #26305e;
		height: 65px;

		.MuiBottomNavigationAction-label {
			color: #ffffff;
			line-height: 20px;
			padding-top: 2%;
			font-size: 14px;
		}
	}

	.menu {
		background: #26305e;
		bottom: 0;
		top: fixed;
		width: 100%;
		height: 50px;
	}

	.menu2 {
		width: 25%;
		right: 0;
		top: 0;
		height: 50px;
		position: absolute;
		text-align: right;
	}

	.menu1 {
		bottom: 0;
		position: fixed;
		width: 100%;
		height: 65px;
	}

	.MuiButton-root {
		padding: 0 0;
	}

	.MuiButton-text {
		padding: 0 0;
	}
}
